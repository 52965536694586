






import Vue from 'vue'
import { deleteDB } from 'idb'

export default Vue.extend({
  name: 'Effacer',
  data() {
    return {}
  },
  methods: {
    async effacerDB() {
      const rep = await deleteDB('maintenance')
      alert('effacé')
    }
  }
})
